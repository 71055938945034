import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useResendOtpMutation,
  useVerifyOtpMutation,
} from "../../service/authService";
import { enqueueSnackbar } from "notistack";
import { Button, Form, Spinner } from "react-bootstrap";
import img from "../../asset/img/logo2.png";

const VerifyOtp = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(30);
  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();
  const [resendOtp] = useResendOtpMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = location.state || {};

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Automatically focus next input if a digit is entered
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const enteredOtp = otp.join("");

    verifyOtp({
      body: { otp: Number(enteredOtp) },
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar({
          variant: "success",
          message: "OTP verified successfully!",
        });
        navigate("/login");
      })
      .catch(() => {
        enqueueSnackbar({
          variant: "error",
          message: "Invalid OTP. Please try again.",
        });
      });
  };

  const handleResendOtp = () => {
    if (timer === 0) {
      resendOtp()
        .unwrap()
        .then((res) => {
          setTimer(30);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div className="login_container">
      <Form
        onSubmit={handleSubmit}
        className="container_main_login"
        style={{
          background: "rgba(13, 110, 253, 0.1)",
          borderRadius: "15px",
          overflow: "hidden",
          border: "2px solid rgba(13, 110, 253, 0.1)",
        }}
      >
        <div className="w-100" style={{ left: "0", top: "0%" }}>
          <h2
            className="d-flex justify-content-center align-items-center "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              fontWeight: "bolder",
              height: "9vh",
              backgroundColor: "white",
            }}
          >
            <img
              style={{ marginRight: "10px" }}
              src={img}
              alt="ii"
              height={35}
            />
            Crypto538
          </h2>
        </div>

        <div className="h-auto p-5 login_form d-flex flex-column justify-content-between gap-3">
          <Form.Group controlId="formBasicOtp">
            <Form.Label>Enter OTP</Form.Label>
            <div className="otp-input-container">
              {otp.map((digit, index) => (
                <Form.Control
                  key={index}
                  id={`otp-${index}`}
                  type="text"
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  maxLength="1"
                  className="otp-input"
                />
              ))}
            </div>
          </Form.Group>
          <Button type="submit" className="border-0" disabled={isLoading}>
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Verify OTP"
            )}
          </Button>
          <a
            style={{
              cursor: timer === 0 ? "pointer" : "not-allowed",
              color: timer === 0 ? "black" : "grey",
              textAlign: "right",
            }}
            onClick={handleResendOtp}
          >
            {timer === 0 ? "Resend OTP" : `Resend OTP in ${timer}s`}
          </a>
        </div>
      </Form>
    </div>
  );
};

export default VerifyOtp;
