import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const cryptoDataService = createApi({
  reducerPath: "cryptoDataService",
  baseQuery: fetchBaseQuery({ baseUrl: "" }),
  endpoints: (builder) => ({
    getCryptoList: builder.query({
      query: () => "https://predictivecoin.ngrok.app/crypto538dashboard",
    }),
    getCryptoGlobalStats: builder.query({
      query: () => "https://api.coingecko.com/api/v3/global",
      transformResponse: (res) => res?.data,
    }),
    getTrendingCoin: builder.query({
      query: () => "https://api.coingecko.com/api/v3/search/trending",
      transformResponse: (res) => res?.coins,
    }),
    getCoinDetail: builder.query({
      query: (id) => `https://predictivecoin.ngrok.app/chart_data/${id}`,
    }),
    getPredictionChartData: builder.query({
      query: () => `https://predictivecoin.ngrok.app/prediction_data_chart`,
      transformResponse: (res) => res?.data,
    }),
    getActualChartData: builder.query({
      query: () => `https://predictivecoin.ngrok.app/actual_data_chart`,
      transformResponse: (res) => res?.data,
    }),
  }),
});

export const {
  useGetCryptoListQuery,
  useGetCryptoGlobalStatsQuery,
  useGetTrendingCoinQuery,
  useGetCoinDetailQuery,
  useGetPredictionChartDataQuery,
  useGetActualChartDataQuery,
} = cryptoDataService;
