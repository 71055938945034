import { authService } from "../service/authService";
import { cryptoDataService } from "../service/cryptoDataService";
import { subscriptionService } from "../service/subscriptionService";
import applicationSlice from "../slice/applicationSlice";

const rootReducer = {
  application: applicationSlice,
  [authService.reducerPath]: authService.reducer,
  [cryptoDataService.reducerPath]: cryptoDataService.reducer,
  [subscriptionService.reducerPath]: subscriptionService.reducer,
};

export default rootReducer;
