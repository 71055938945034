import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  NavLink,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../helper/i18next";
import img from "../../asset/img/logo2.png";
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import {
  useRegisterUserMutation,
  useUserLoginMutation,
} from "../../service/authService";
import { useGoogleLogin } from "@react-oauth/google";
import { setauth, setUser } from "../../slice/applicationSlice";
import { LoginType } from "../../constants/loginType";

const SignupPage = () => {
  const { t } = useTranslation();

  const [userRegister, { isLoading }] = useRegisterUserMutation();
  const [login, { isLoading: isLoggingin }] = useUserLoginMutation();
  const [passwordVisiblity, setPasswordVisibility] = useState(false);
  const [repasswordVisiblity, setRepasswordVisibility] = useState(false);

  const dispatch = useDispatch();

  const currentLanguage = useSelector(
    (state) => state.application.currentLanguage
  );
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, []);

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    reenterPassword: "",
    agreeTerms: false,
  });

  const [formError, setFormError] = useState({
    fname: false,
    lname: false,
    email: false,
    password: false,
    reenterPassword: false,
    agreeTerms: false,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    handleFormError(name, !value);
  };

  const handleFormError = (name, value) => {
    setFormError({
      ...formError,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {
      fname: !formData.fname,
      lname: !formData.lname,
      email: !formData.email,
      password: !formData.password,
      reenterPassword: !formData.reenterPassword,
      agreeTerms: !formData.agreeTerms,
    };
    setFormError(errors);
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      return;
    }
    // const allValuesPresent = Object.keys(formData)
    //   .map((key) => formData[key])
    //   .every((value) => value !== null && value !== undefined && value !== "");
    // const anyValueNotPresent = Object.keys(formData).some(
    //   (key) =>
    //     formData[key] === null ||
    //     formData[key] === undefined ||
    //     formData[key] === ""
    // );
    // console.log(anyValueNotPresent, "anyValueNotPresent");
    // // if (!allValuesPresent) {
    // //   handleFormError("fname", true);
    // //   handleFormError("lname", true);
    // //   handleFormError("email", true);
    // //   handleFormError("password", true);
    // //   handleFormError("reenterPassword", true);
    // //   handleFormError("checkbox", true);
    // //   return;
    // // }

    // if (anyValueNotPresent) {
    //   !formData.fname && handleFormError("fname", true);
    //   !formData.lname && handleFormError("lname", true);
    //   !formData.email && handleFormError("email", true);
    //   !formData.password && handleFormError("password", true);
    //   !formData.reenterPassword && handleFormError("reenterPassword", true);
    //   !formData.agreeTerms && handleFormError("agreeTerms", true);
    //   return;
    // }

    if (formData.password !== formData.reenterPassword) {
      enqueueSnackbar({
        variant: "error",
        message: "Passwords do not match",
      });
      return;
    }
    const body = {
      firstName: formData?.fname,
      lastName: formData?.lname,
      email: formData?.email,
      password: formData?.password,
    };
    userRegister(body)
      .unwrap()
      .then((res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Signup successful! Please check your email for OTP.",
        });
        setFormData({});
        navigate("/verify-otp", { state: { userId: res?.id } });
      })
      .catch(() => {
        enqueueSnackbar({
          variant: "error",
          message: "Something went wrong, Please try again later.",
        });
      });
  };
  const getUserDetails = async (accessToken) => {
    const response = await fetch(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user details");
    }

    const userData = await response.json();
    const userDetails = {
      email: userData.email,
      firstName: userData.given_name,
    };
    return userDetails;
  };
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      const data = await getUserDetails(response.access_token);
      login({ ...data, type: LoginType.GOOGLE })
        .unwrap()
        .then((res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Login successful",
          });
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("userDetail", JSON.stringify(res?.data));
          dispatch(setUser(JSON.stringify(res?.data)));
          dispatch(setauth(true));
          navigate("/");
        })
        .catch(() => {
          enqueueSnackbar({
            variant: "error",
            message: "Invalid email or password",
          });
        });
    },
    onError: () => {
      console.log("Login Failed");
    },
    scope: "openid email profile",
  });

  return (
    <div style={{ maxWidth: "500px", margin: "15px auto 0" }}>
      {" "}
      {/* <div className="d-flex justify-content-center align-items-center"> */}
      {/* <Col md={5} className=" d-flex flex-column "> */}
      <Form
        className="d-flex flex-column gap-2"
        style={{
          background: "rgba(13, 110, 253, 0.1)",
          borderRadius: "15px",
          overflow: "hidden",
          border: "2px solid rgba(13, 110, 253, 0.1)",
        }}
      >
        <h2
          className="d-flex justify-content-center align-items-center w-100 "
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            fontWeight: "bolder",
            fontSize: "xx-large",
            height: "9vh",
            backgroundColor: "white",
          }}
        >
          <img style={{ marginRight: "10px" }} src={img} alt="ii" height={35} />
          {t("ribbon.brand")}
        </h2>
        <div className="d-flex flex-column justify-content-center align-items-center px-5 py-4">
          <Form.Group controlId="formBasicFName" className="w-100 mb-2">
            <Form.Label>{t("signup.formLabels.fname")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your first name"
              name="fname"
              value={formData.fname}
              onChange={handleChange}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: formError.fname ? "block" : "none" }}
            >
              Please provide a first name
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicLName" className="w-100 mb-2">
            <Form.Label>{t("signup.formLabels.lname")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your last name"
              name="lname"
              value={formData.lname}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: formError.lname ? "block" : "none" }}
            >
              Please provide a last name
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicEmail" className="w-100 mb-2">
            <Form.Label>{t("signup.formLabels.email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: formError.email ? "block" : "none" }}
            >
              Please provide a email
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicPassword" className="w-100 mb-2">
            <Form.Label>{t("signup.formLabels.password")}</Form.Label>
            <InputGroup>
              <Form.Control
                type={passwordVisiblity ? "text" : "password"}
                placeholder="Enter your password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <InputGroup.Text
                onClick={() => setPasswordVisibility(!passwordVisiblity)}
              >
                {passwordVisiblity ? (
                  <i class="bi bi-eye-slash"></i>
                ) : (
                  <i class="bi bi-eye"></i>
                )}
              </InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback
              type="invalid"
              style={{ display: formError.password ? "block" : "none" }}
            >
              Please provide a password
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            controlId="formBasicReenterPassword"
            className="w-100 mb-2"
          >
            <Form.Label>{t("signup.formLabels.reenterPassword")}</Form.Label>
            <InputGroup>
              <Form.Control
                type={repasswordVisiblity ? "text" : "password"}
                placeholder="Re-enter your password"
                name="reenterPassword"
                value={formData.reenterPassword}
                onChange={handleChange}
                required
              />
              <InputGroup.Text
                onClick={() => setRepasswordVisibility(!repasswordVisiblity)}
              >
                {repasswordVisiblity ? (
                  <i class="bi bi-eye-slash"></i>
                ) : (
                  <i class="bi bi-eye"></i>
                )}
              </InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback
              type="invalid"
              style={{ display: formError.reenterPassword ? "block" : "none" }}
            >
              Please re-enter your password
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicCheckbox" className="w-100 mb-2">
            <div className="d-flex">
              <Form.Check
                style={{ color: "blue" }}
                type="checkbox"
                name="agreeTerms"
                checked={formData.agreeTerms}
                onChange={handleChange}
                required
              />
              <Form.Label className="d-block">
                <Link
                  style={{ marginLeft: "5px", fontWeight: 400 }}
                  className="forget_pass"
                >
                  {"I agree to the terms and conditions"}
                </Link>
              </Form.Label>
            </div>

            <Form.Control.Feedback
              type="invalid"
              style={{ display: formError.agreeTerms ? "block" : "none" }}
            >
              Please check the box
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            type="button"
            className="btn btn-primary w-100"
            onClick={(e) => handleSubmit(e)}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              t("signup.buttons.signup")
            )}
          </Button>
          <span className="text-center">Or</span>
          <button
            type="button"
            className="w-100 mb-2 login-with-google-btn"
            onClick={() => handleGoogleLogin()}
          >
            Continue with Google
          </button>
          <p className="text-center w-100">
            Already have an account?{" "}
            <Link className="cursor-pointer forget_pass" to="/login">
              Log in
            </Link>
          </p>
        </div>
      </Form>
      {/* </Col> */}
      {/* </div> */}
    </div>
  );
};

export default SignupPage;
