import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const PredictionChart = ({ coinData }) => {
  const options = {
    chart: {
      type: "area",
      height: 350,
      stacked: false,
      toolbar: {
        show: true,
        offsetX: -10,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
    },
    events: {
      mounted: (chart) => {
        // Add event listener to disable zooming on scroll
        chart.el.addEventListener("wheel", (e) => {
          if (e.ctrlKey) {
            // If Ctrl key is not pressed
            e.preventDefault(); // Prevent zoom on scroll
          }
        });
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
    },
    colors: ["#008FFB", "#00E396"],
    legend: {
      position: "top",
      offsetY: 0,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm:ss",
      },
    },
    fill: {
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0.3,
      },
    },
    annotations: {
      yaxis: [
        {
          y: coinData[0].actual[coinData[0].actual.length - 1].value,
          borderColor: "#008FFB",
          label: {
            borderColor: "#008FFB",
            style: {
              color: "#fff",
              background: "#008FFB",
            },
            text:
              "Actual: " +
              coinData[0].actual[coinData[0].actual.length - 1].value.toFixed(
                2
              ),
          },
        },
        {
          y: coinData[0].predict[coinData[0].predict.length - 1].value,
          borderColor: "#00E396",
          label: {
            borderColor: "#00E396",
            style: {
              color: "#fff",
              background: "#00E396",
            },
            text:
              "Predicted: " +
              coinData[0].predict[coinData[0].predict.length - 1].value.toFixed(
                2
              ),
          },
        },
      ],
    },
    stroke: {
      dashArray: [0, 5],
    },
    series: [
      {
        name: "Actual",
        data: coinData[0].actual.map((item) => [
          new Date(item.time * 1000).toISOString(),
          item.value,
        ]),
      },
      {
        name: "Predicted",
        data: coinData[0].predict.map((item) => [
          new Date(item.time * 1000).toISOString(),
          item.value,
        ]),
      },
    ],
  };

  return (
    <div>
      <div id="chart">
        {/* <div className="toolbar">
          <button
            id="one_month"
            onClick={() => handleRangeChange("one_month")}
            className={selection === "one_month" ? "active" : ""}
          >
            1M
          </button>

          <button
            id="six_months"
            onClick={() => handleRangeChange("six_months")}
            className={selection === "six_months" ? "active" : ""}
          >
            6M
          </button>

          <button
            id="one_year"
            onClick={() => handleRangeChange("one_year")}
            className={selection === "one_year" ? "active" : ""}
          >
            1Y
          </button>

          <button
            id="ytd"
            onClick={() => handleRangeChange("ytd")}
            className={selection === "ytd" ? "active" : ""}
          >
            YTD
          </button>

          <button
            id="all"
            onClick={() => handleRangeChange("all")}
            className={selection === "all" ? "active" : ""}
          >
            ALL
          </button>
        </div> */}

        <div id="chart-timeline">
          <ReactApexChart
            options={options}
            series={options.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default PredictionChart;
