import { createSlice } from "@reduxjs/toolkit";

export const applicationSlice = createSlice({
  name: "applicationSlice",
  initialState: {
    currentLanguage: "en",
    auth: "",
    user: "",
  },
  reducers: {
    setLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
    setauth: (state, action) => {
      state.auth = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setLanguage, setauth, setUser } = applicationSlice.actions;

export default applicationSlice.reducer;
