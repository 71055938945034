import { Skeleton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import TableLoader from "./TableLoader";

export const HomePageLoader = () => {
  return (
    <div className="vh-100 overflow-hidden">
      <div className="d-flex flex-column container-xxl">
        <Skeleton
          variant="rectangular"
          height={30}
          style={{ marginBottom: "5px" }}
        />
        <Skeleton
          variant="rectangular"
          height={65}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton
          variant="rectangular"
          width={600}
          height={45}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton
          variant="rectangular"
          width={700}
          height={25}
          style={{ marginBottom: "1rem" }}
        />
        <div className="d-flex justify-content-around row mb-5">
          <div className="col-lg-4 mb-4">
            <Skeleton variant="rectangular" height={200} />
          </div>
          <div className="col-lg-4 mb-4">
            <Skeleton variant="rectangular" height={200} />
          </div>

          <div className="col-lg-4 mb-4">
            <Skeleton variant="rectangular" height={200} />
          </div>
        </div>
        <Table>
          <TableBody>
            <TableLoader row={10} />
          </TableBody>
        </Table>
      </div>

      {/* <Skeleton variant="rectangular"  height={250} />
      <Skeleton variant="rectangular" height={60} /> */}
    </div>
  );
};
