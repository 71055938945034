import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import React, { Component, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import img from "../asset/img/logo2.png";
import { Link, useNavigate } from "react-router-dom";
import { setLanguage, setUser, setauth } from "../slice/applicationSlice";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../helper/i18next";
import { enqueueSnackbar } from "notistack";
import { Avatar } from "@mui/material";
import { useMeQuery } from "../service/authService";

function NavBar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useMeQuery();
  const currentLanguage = useSelector((state) => state.currentLanguage);
  const userData = useSelector((state) => state.application.user);
  const [selectedOption_lang, setSelectedOption_lang] =
    useState(currentLanguage);
  const handleOptionChange_lang = (e) => {
    const selectedLanguage = e.target.value;
    setSelectedOption_lang(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    dispatch(setLanguage(selectedLanguage));
  };

  const handleLogOut = async () => {
    enqueueSnackbar({
      variant: "success",
      message: "Logout successful",
    });
    document.cookie = "user" + "=; Max-Age=-99999999;";
    dispatch(setUser(null));
    navigate("/");
  };
  useEffect(() => {
    dispatch(setUser(data));
  }, [data]);

  return (
    <div
      className="  heding_text_size "
      style={{
        boxShadow: "0.5px 0.5px 5px 0.5px rgba(0,0,0,0.1)",
      }}
    >
      <Navbar expand="lg" className="p-0 w-100 d-flex justify-content-center ">
        <div className="p-0 m-0 container-xxl">
          <Navbar.Brand href="/">
            <div
              style={{ fontWeight: "bolder" }}
              className="d-flex justify-content-center align-items-center"
            >
              <img src={img} alt="ii" className="img_in_nav" />
              <span className="brand_nav">{t("ribbon.brand")}</span>
            </div>
          </Navbar.Brand>
          {userData && (
            <div className=" align-items-center profile_log_out_icon_1">
              {/* <i className="bi bi-person-fill me-4"> </i> */}
              <Avatar
                className="me-4 bg-primary"
                sx={{ width: 35, height: 35, fontSize: "14px" }}
              >
                {userData?.firstName?.charAt(0).toUpperCase()}
              </Avatar>
              <i
                className="bi bi-box-arrow-right bg-danger"
                style={{
                  cursor: "pointer",
                  width: 35,
                  height: 35,
                  textAlign: "center",
                  lineHeight: "35px",
                  // backgroundColor: "#BDBDBD",
                  borderRadius: "50%",
                  color: "white",
                  marginRight: "5px",
                }}
                title="Log out"
                onClick={handleLogOut}
              ></i>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
          )}
          {!userData && <Navbar.Toggle aria-controls="basic-navbar-nav" />}

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {!userData && (
                <Nav.Link
                  href="/login"
                  id="log_in_box"
                  style={{ color: "grey" }}
                >
                  {t("landingHeader.login")}
                </Nav.Link>
              )}
              {!userData && (
                <Nav.Link
                  href="/singup"
                  id="log_in_box"
                  style={{ color: "grey" }}
                >
                  {t("landingHeader.signup")}
                </Nav.Link>
              )}
              <NavDropdown
                title={t("ribbon.Cryptocurrencies")}
                id="basic-nav-dropdown"
              ></NavDropdown>
              <NavDropdown
                title={t("ribbon.Exchanges")}
                id="basic-nav-dropdown"
              ></NavDropdown>
              <NavDropdown
                title={t("ribbon.Community")}
                id="basic-nav-dropdown"
              ></NavDropdown>
              <NavDropdown
                title={t("ribbon.Products")}
                id="basic-nav-dropdown"
              ></NavDropdown>
              <NavDropdown
                title={t("ribbon.learn")}
                id="basic-nav-dropdown"
              ></NavDropdown>
              <Nav.Link
                onClick={() => navigate("/plan")}
                style={{ color: "grey" }}
              >
                Upgrade Plan
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="ms-auto align-items-center profile_log_out_icon">
            {/* <div className="h-auto d-flex align-content-center">
              <select
                className="rem_bor me-4"
                value={selectedOption_lang}
                onChange={handleOptionChange_lang}
              >
                <option value="en">English</option>
                <option value="es">Espenol</option>
              </select>
            </div> */}
            {userData ? (
              <>
                {/* <i className="bi bi-person-fill me-4"> </i> */}
                <Avatar
                  className="me-4 bg-primary"
                  sx={{ width: 35, height: 35, fontSize: "14px" }}
                >
                  {userData?.firstName?.charAt(0).toUpperCase()}
                </Avatar>
                <i
                  className="bi bi-box-arrow-right bg-danger"
                  style={{
                    cursor: "pointer",
                    width: 35,
                    height: 35,
                    textAlign: "center",
                    lineHeight: "35px",
                    // backgroundColor: "#BDBDBD",
                    borderRadius: "50%",
                    color: "white",
                    marginRight: "5px",
                  }}
                  title="Log out"
                  onClick={handleLogOut}
                ></i>
              </>
            ) : (
              <>
                <div className="log_in_box h-auto d-flex  align-content-center flex-wrap bg-primary">
                  <Link className="remove_dec text-white" to="/login">
                    {t("landingHeader.login")}
                  </Link>
                </div>

                <div className="log_in_box h-auto d-flex align-content-center flex-wrap bg-success">
                  <Link className="remove_dec text-white" to="/signup">
                    {t("landingHeader.signup")}
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </Navbar>
    </div>
  );
}

export default NavBar;
