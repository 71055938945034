import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { Subscription } from "../constants/subscription";

const formatMarketCap = (marketCap) => {
  if (marketCap >= 1e12) return `${(marketCap / 1e12).toFixed(2)} T`;
  if (marketCap >= 1e9) return `${(marketCap / 1e9).toFixed(2)} B`;
  if (marketCap >= 1e6) return `${(marketCap / 1e6).toFixed(2)} M`;
  return marketCap?.toFixed(2);
};

const generateSparklineChart = (data, color) => {
  const options = {
    chart: {
      sparkline: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    series: [
      {
        data: data,
      },
    ],
    colors: [color],
    stroke: {
      curve: "smooth",
      width: 1,
    },
    markers: {
      size: 0,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={options.series}
      type="line"
      width={100}
      height={35}
    />
  );
};

const renderRankCell = (params) => (
  <Box display="flex" alignItems="center">
    {params?.row?.market_cap_rank}
  </Box>
);

const renderNameCell = (params) => (
  <Box display="flex" alignItems="center">
    <Avatar alt={params?.row?.name} src={params?.row?.image} />
    <Box ml={2}>{params?.row?.name}</Box>
  </Box>
);

const renderPriceCell = (params) => (
  <div>{params?.value ? `$${params.value}` : "-"}</div>
);

const renderChangeCell = (params) => {
  const color = params.value > 0 ? "green" : "red";
  return (
    <div style={{ color }}>
      {params?.value ? `${params?.value?.toFixed(2)}%` : "-"}
    </div>
  );
};

const renderMarketCapCell = (params) => (
  <div>{`$${formatMarketCap(params?.value)}`}</div>
);

const renderSparklineCell = (params) =>
  generateSparklineChart(
    params?.row?.sparkline_in_7d,
    params?.row?.price_change_percentage_1h_in_currency > 0
      ? "#008000"
      : "#ff0000"
  );

const getColumns = (userData) => [
  {
    field: "market_cap_rank",
    headerName: "Rank",
    width: 100,
    renderCell: renderRankCell,
  },
  {
    field: "name",
    headerName: "Name",
    width: 250,
    renderCell: renderNameCell,
  },
  {
    field: "current_price",
    headerName: "Price",
    width: 150,
    renderCell: renderPriceCell,
  },
  ...(userData
    ? [
        {
          field: "price_change_percentage_1h_in_currency",
          headerName: "1hr Predicted",
          width: 150,
          renderCell: renderChangeCell,
        },
        {
          field: "price_change_percentage_24h_in_currency",
          headerName: "24hr Predicted",
          width: 150,
          renderCell: renderChangeCell,
        },
        {
          field: "price_change_percentage_7d_in_currency",
          headerName: "7d Predicted",
          width: 150,
          renderCell: renderChangeCell,
        },
      ]
    : [
        {
          field: "price_change_percentage_1h_in_currency",
          headerName: "1hr Change",
          width: 150,
          renderCell: renderChangeCell,
        },
        {
          field: "price_change_percentage_24h_in_currency",
          headerName: "24hr Change",
          width: 150,
          renderCell: renderChangeCell,
        },
        {
          field: "price_change_percentage_7d_in_currency",
          headerName: "7d Change",
          width: 150,
          renderCell: renderChangeCell,
        },
      ]),
  {
    field: "market_cap",
    headerName: "Market Cap",
    width: 180,
    valueFormatter: formatMarketCap,
    renderCell: renderMarketCapCell,
  },
  {
    field: "sparkline",
    headerName: "Sparkline",
    width: 150,
    renderCell: renderSparklineCell,
    sortable: false,
    filterable: false,
  },
];

const CoinTable = ({ coinDataList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filteredCoins, setFilteredCoins] = useState(coinDataList);
  const userData = useSelector((state) => state.application.user);

  useEffect(() => {
    const coinsToShow = coinDataList.slice(0, 10); // Ensure only 10 rows are shown
    setFilteredCoins(coinsToShow);
  }, [coinDataList]);

  const handleSearch = (e) => {
    if (e.key === "Enter" || !e.target.value) {
      const term = e.target.value.toLowerCase();
      const filtered = coinDataList?.filter((coin) =>
        coin.name.toLowerCase().includes(term)
      );
      setFilteredCoins(filtered);
    }
  };

  const coininfo = (coin) => {
    if (userData) {
      navigate("/coindetails", { state: coin });
    } else {
      navigate("/signup");
    }
  };

  const getRowClassName = (params) => {
    const index = params.indexRelativeToCurrentPage;
    // const index = params.id - 1; // Assuming IDs are sequential and start from 1
    if (userData?.planType === Subscription.STANDARD && index >= 5)
      return "blur-row";
    if (userData?.planType === Subscription.PREMIUM) return "";
    if (userData?.planType === "free" && index >= 2) {
      return "blur-row";
    }
  };

  const columns = useMemo(() => getColumns(userData), [userData]);

  return (
    <div className="d-flex flex-column gap-3 mt-4 container-xxl">
      <div className="d-flex flex-column justify-content-center align-items-center w-100 mx-auto">
        <Form.Group className="custom_input_search_box_lander_table_outer_div d-flex justify-content-end align-self-end mb-2">
          <Form.Control
            type="text"
            placeholder={t("table.search")}
            onKeyDown={handleSearch}
            className="custom_input_search_box_lander_table"
            onBlur={handleSearch}
          />
        </Form.Group>
        <div className="w-100">
          <DataGrid
            rows={filteredCoins}
            columns={columns}
            getRowId={(row) => row.id}
            hideFooter={true}
            getRowClassName={getRowClassName}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            onRowClick={({ row }) =>
              !getRowClassName({ id: row.id }) && coininfo(row)
            }
            autoHeight
            sx={{
              "& .MuiDataGrid-footerContainer p": {
                margin: 0,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#0d6efd", // Set your desired background color
                color: "#fff", // Set text color to white
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold", // Optional: make the header text bold
              },
              "& .MuiDataGrid-row--borderBottom": {
                background: "#0d6efd !important",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CoinTable;
