import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";

export const authService = createApi({
  reducerPath: "authService",
  baseQuery,
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (body) => ({
        url: "auth/login",
        method: "POST",
        body,
      }),
    }),
    registerUser: builder.mutation({
      query: (body) => ({
        url: "auth/register",
        method: "POST",
        body,
      }),
    }),
    verifyOtp: builder.mutation({
      query: ({ body, header }) => ({
        url: "auth/verify",
        method: "POST",
        body,
        headers: {
          ...header,
        },
      }),
    }),
    me: builder.query({
      query: () => "me",
      transformResponse: (res) => res?.data,
    }),
    resendOtp: builder.mutation({
      query: () => ({
        url: "auth/resend-otp",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useUserLoginMutation,
  useRegisterUserMutation,
  useVerifyOtpMutation,
  useMeQuery,
  useLazyMeQuery,
  useResendOtpMutation,
} = authService;
