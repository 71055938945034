import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import { setLanguage, setauth } from "../slice/applicationSlice";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../helper/i18next";
import { useGetCryptoGlobalStatsQuery } from "../service/cryptoDataService";
const CryptoStats = () => {
  const { data: cryptoGlobalStats, isSuccess: loadingGlobalStats } =
    useGetCryptoGlobalStatsQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(
    (state) => state.application.currentLanguage
  );
  const [selectedOption_lang, setSelectedOption_lang] =
    useState(currentLanguage);
  const [selectedOption_curency, setSelectedOption_curency] = useState("USD");

  const handleOptionChange_lang = (e) => {
    const selectedLanguage = e.target.value;
    setSelectedOption_lang(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    dispatch(setLanguage(selectedLanguage));
  };

  const color = {
    market_cap_change_percentage_24h_usd:
      cryptoGlobalStats?.market_cap_change_percentage_24h_usd > 0
        ? "green"
        : "red",
  };
  const formatNumber = (number) => {
    const suffixes = ["", "K", "M", "B", "T"];
    let suffixNum = 0;

    while (number >= 1000 && suffixNum < suffixes.length - 1) {
      suffixNum++;
      number /= 1000;
    }
    return `${number?.toFixed(2)} ${suffixes[suffixNum]}`;
  };

  return (
    <>
      {loadingGlobalStats && (
        <div className="header d-flex justify-content-between  heding_text_size container-xxl">
          <div className="d-flex align-content-center flex-wrap">
            <div className="header-text  justify-content-between align-items-center ">
              {t("api.active_cryptocurrencies")}:{" "}
              <span>{cryptoGlobalStats?.active_cryptocurrencies}</span> |{" "}
              {t("api.in_market_coins")}:{" "}
              <span>{cryptoGlobalStats?.markets}</span> |{" "}
              {t("api.total_market_cap")}:{" "}
              <span>
                ${formatNumber(cryptoGlobalStats?.total_market_cap.aud)}
              </span>{" "}
              | {t("api.total_volume")}:{" "}
              <span>${formatNumber(cryptoGlobalStats?.total_volume.btc)}</span>{" "}
              | {t("api.market_cap_change_percentage_24h_usd")}:
              <span
                style={{
                  color:
                    cryptoGlobalStats?.market_cap_change_percentage_24h_usd > 0
                      ? "green"
                      : "red",
                }}
              >
                <i
                  className={
                    cryptoGlobalStats?.market_cap_change_percentage_24h_usd > 0
                      ? "bi bi-caret-up-fill"
                      : "bi bi-caret-down-fill"
                  }
                ></i>
                {parseFloat(
                  cryptoGlobalStats?.market_cap_change_percentage_24h_usd.toFixed(
                    2
                  )
                )}
                %
              </span>{" "}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CryptoStats;
