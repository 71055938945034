import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";

export const subscriptionService = createApi({
  reducerPath: "subscriptionService",
  baseQuery,
  endpoints: (builder) => ({
    subscribe: builder.mutation({
      query: ({ type, period }) => ({
        url: `subscribe?type=${type}&period=${period}`,
        method: "POST",
      }),
      transformResponse: (res) => res?.data,
    }),
  }),
});
export const { useSubscribeMutation } = subscriptionService;
