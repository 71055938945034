import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginForm from "./pages/auth/LoginForm";
import "./asset/style/globalStyle.css";
import Home from "./pages/home";
import CoinDetails from "./pages/coinDetail";
import SignupPage from "./pages/auth/Signup";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  setTabledata,
  setcarsoul,
  setHeaderData,
  setUser,
} from "./slice/applicationSlice";
import SubscriptionPlans from "./pages/plans/SubscriptionPlans";
import { SnackbarProvider } from "notistack";
import PaypalCheckout from "./pages/plans/PaypalCheckout";
import Stripecheckout from "./pages/plans/StripeCheckout";
import VerifyOtp from "./pages/auth/VerifyOtp";
import { useMeQuery } from "./service/authService";
import SuccessFullSubscription from "./pages/plans/SuccessFullSubscription";
const App = () => {
  const { data: userData } = useMeQuery();
  const dispatch = useDispatch();
  dispatch(setUser(userData));

  return (
    <div>
      {
        <SnackbarProvider
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={1500}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/coindetails" element={<CoinDetails />} />
              <Route path="/verify-otp" element={<VerifyOtp />} />
              <Route path="/plan" element={<SubscriptionPlans />} />
              <Route path="/success" element={<SuccessFullSubscription />} />
              {/* <Route path="/payments" element={<PaypalCheckout />} /> */}
              <Route path="/payments" element={<Stripecheckout />} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      }
    </div>
  );
};

export default App;
