import React, { useState, useEffect } from "react";
import img from "../../asset/img/logo2.png";
import i18n from "../../helper/i18next";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Stripecheckout from "./StripeCheckout";
import { useSubscribeMutation } from "../../service/subscriptionService";
import { Subscription } from "../../constants/subscription";
function SubscriptionPlans() {
  const lang = useSelector((state) => state.application.currentLanguage);
  const userData = useSelector((state) => state.application.user);
  const [subscribe, { isLoading }] = useSubscribeMutation();
  const { t } = useTranslation();
  const [yearly, setYearly] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);
  const handleClick = (plan) => {
    const period = yearly ? "yearly" : "monthly";
    if (userData && plan !== "free") {
      subscribe({ type: plan, period }).then((res) => {
        window.location.href = res?.data?.url;
      });
    } else if (userData && plan === "free") {
      navigate("/");
    } else {
      navigate("/login");
    }
  };
  return (
    <div className="h-100">
      <h2
        className="d-flex justify-content-center align-items-center w-100 "
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          fontWeight: "bolder",
          fontSize: "xx-large",
        }}
      >
        <img style={{ marginRight: "10px" }} src={img} alt="ii" height={35} />
        {t("ribbon.brand")}
      </h2>
      <div className="w-100 d-flex justify-content-center align-items-center  gap-2">
        <div>{t("plan_page.month")}</div>
        <div className="form-check form-switch d-flex justify-content-center align-items-center">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            defaultChecked={yearly}
            onChange={() => setYearly(!yearly)}
          />
        </div>
        <div>{t("plan_page.annualy")}</div>
      </div>
      <div className="w-100 main_price container mt-3">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <div
              className="h-100 rounded-4 overflow-hidden"
              style={{ boxShadow: "1px 1px 8px 1px rgba(0,0,0,0.2)" }}
            >
              <div className="text-center bg-primary price_heading d-flex justify-content-center align-items-center fs-5">
                Freemium Model
              </div>
              <div className="p-3 content_in_box_price">
                <div>
                  <p className="text-center mb-1 fw-bold">Free</p>
                </div>
                <div className="d-flex justify-content-center p-2">
                  {/* <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleClick("free")}
                  >
                    Continue
                  </button> */}
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleClick("free")}
                  >
                    Continue
                  </button>
                </div>
                <ul className="price_ofersx mt-3">
                  <li>{t("plan_page.plan1.a_T_ctp_an")}</li>
                  <li>{t("plan_page.plan1.a_PFfor_")}</li>
                  <li>{t("plan_page.plan1.acce_14_hist")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" col-lg-4 col-md-6 col-sm-12 mb-5">
            <div
              className="h-100 rounded-4 overflow-hidden"
              style={{ boxShadow: "1px 1px 8px 1px rgba(0,0,0,0.2)" }}
            >
              <div className="text-center bg-primary  price_heading d-flex justify-content-center align-items-center fs-5">
                Standard plan
              </div>
              <div className="p-3 content_in_box_price">
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  {yearly ? (
                    <div>
                      <p className="text-center mb-1 fw-bold">
                        {t("plan_page.plan2.y_1_2_m_f")}
                      </p>
                    </div>
                  ) : (
                    <p className="text-center mb-1 fw-bolder">
                      {t("plan_page.plan2.m_15")}
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-center p-2">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleClick(Subscription.STANDARD)}
                  >
                    Continue
                  </button>
                </div>
                <ul className="price_ofersx mt-3">
                  <li>{t("plan_page.plan2.n_ad")}</li>
                  <li>{t("plan_page.plan2.e_e_c_a_t_c")}</li>
                  <li>{t("plan_page.plan2.a_t_c_a")}</li>
                  <li>{t("plan_page.plan2.a_t_p_f_t_n_1_m")}</li>
                  <li>{t("plan_page.plan2.a_t__his")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" col-lg-4 col-md-6 col-sm-12 mb-5">
            <div
              className="h-100 rounded-4 overflow-hidden"
              style={{ boxShadow: "1px 1px 8px 1px rgba(0,0,0,0.2)" }}
            >
              <div className="text-center bg-primary price_heading d-flex justify-content-center align-items-center fs-5">
                Premium plan
              </div>
              <div className="p-3 content_in_box_price">
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  {yearly ? (
                    <div>
                      <p className="text-center mb-1 fw-bold">
                        {t("plan_page.plan3.y_1_2_m_f")}
                      </p>
                      {/* <p style={{ fontSize: "10px" }} className="fw-bold">
                        {t("plan_page.plan3.y_w_1_d")}
                      </p> */}
                    </div>
                  ) : (
                    <p className="text-center mb-1 fw-bolder">
                      {t("plan_page.plan3.m_15")}
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-center p-2">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleClick(Subscription.PREMIUM)}
                  >
                    Continue
                  </button>
                </div>
                <ul className="price_ofersx mt-3">
                  <li>{t("plan_page.plan3.n_ad")}</li>
                  <li>{t("plan_page.plan3.e_e_c_a_t_c")}</li>
                  <li>{t("plan_page.plan3.a_t_c_a")}</li>
                  <li>{t("plan_page.plan3.a_t_p_f_t_n_1_m")}</li>
                  <li>{t("plan_page.plan3.a_t__his")}</li>
                  <li>{t("plan_page.plan3.o_f_p_a_s")}</li>
                  <li>{t("plan_page.plan3.l_c_w_a_a")}</li>
                  <li>{t("plan_page.plan3.o_d_a_t_a")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPlans;
