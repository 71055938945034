import React, { useEffect } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { useTranslation } from "react-i18next";
const styles = {
  dial: {
    display: "inline-block",
    width: `300px`,
    height: `20px`,
    color: "#000",
    border: "0.5px solid #fff",
    padding: "2px",
  },
  title: {
    fontSize: "1em",
    color: "#000",
  },
};

const CryptoGaugeChart = ({ cryptoGlobalStats }) => {
  const marketCapData = cryptoGlobalStats?.market_cap_change_percentage_24h_usd;

  const { t } = useTranslation();
  return (
    <div className="h-100">
      <div
        className=" h-100 d-flex flex-column justify-content-center p-3"
        style={{
          borderRadius: "20px",
          height: "100%",
          boxShadow: "1px 1px 8px 1px rgba(0,0,0,0.2)",
        }}
      >
        <div className="w-100">
          <h6>{t("api.market_cap_change_percentage_24h_usd")} </h6>{" "}
        </div>
        <div
          style={styles.title}
          className="overflow-hidden garph d-flex justify-content-center align-items-center w-100 "
        >
          <ReactSpeedometer
            maxValue={20}
            minValue={-20}
            value={parseFloat(marketCapData?.toFixed(4))}
            needleColor="red"
            startColor="red"
            segments={10}
            maxSegmentLabels={10}
            labelFontSize={"10"}
            valueTextFontSize={"10"}
            endColor="green"
            textColor="black"
            height={150}
            width={250}
            ringWidth={10}
          />
        </div>
      </div>
    </div>
  );
};

export default CryptoGaugeChart;
