import { Skeleton } from "@mui/material";
import React from "react";

const CoinInfoLoader = () => {
  return (
    <div className="vh-100 overflow-hidden">
      <div className="d-flex flex-column container-xxl">
        <Skeleton
          variant="rectangular"
          height={30}
          style={{ marginBottom: "5px" }}
        />
        <Skeleton
          variant="rectangular"
          height={65}
          style={{ marginBottom: "1rem" }}
        />
        <div style={{ marginTop: "5px", display: "flex" }}>
          <div className="w-25">
            <Skeleton
              variant="rectangular"
              style={{ marginRight: "1rem", height: "100vh" }}
            />
          </div>
          <div className="w-75">
            <Skeleton
              variant="rectangular"
              style={{ marginBottom: "1rem", height: "50vh" }}
            />
            <Skeleton
              variant="rectangular"
              style={{ marginBottom: "1rem", height: "50vh" }}
            />
          </div>
        </div>
      </div>

      {/* <Skeleton variant="rectangular"  height={250} />
  <Skeleton variant="rectangular" height={60} /> */}
    </div>
  );
};

export default CoinInfoLoader;
