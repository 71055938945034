import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "react-bootstrap";
import { Tabs, Tab } from "@mui/material";
import CoinInfo from "./CoinInfo.js";
import cData from "../../data/sampleCoinData.json";
import { TestData } from "../../utils/helper.js";
import ListOfData from "../../data/expectedDataFormat.json";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import i18n from "../../helper/i18next.js";
import PredictionChart from "../../components/PredictionChart.js";
import { TestSingleData } from "../../utils/helper.js";
import TimeBasedPredictionChart from "../../components/TimeBasedPredictionChart.js";
import CryptoStats from "../../components/CryptoStats.js";
import NavBar from "../../components/NavBar.js";
import {
  useGetActualChartDataQuery,
  useGetPredictionChartDataQuery,
} from "../../service/cryptoDataService.js";
const SampleData = ListOfData.data;

const CoinGraphs = ({ accuracyCoinData, predictionCoinData }) => {
  const location = useLocation();
  const currentLanguage = useSelector(
    (state) => state.application.currentLanguage
  );
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          padding: "10px",
          boxShadow: "0px 0px 10px #00000012",
          marginLeft: "5px",
          marginTop: "5px",
          // height: "500px",
        }}
      >
        {<PredictionChart coinData={predictionCoinData} />}
      </div>
      <div
        style={{
          width: "100%",
          padding: "10px",
          boxShadow: "0px 0px 10px #00000012",
          marginLeft: "5px",
          marginTop: "5px",
          // height: "500px",
        }}
      >
        {<TimeBasedPredictionChart coinData={accuracyCoinData} />}
      </div>
    </div>
  );
};

export default CoinGraphs;
