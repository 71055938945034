import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CoinTable from "../../components/CoinTable.js";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../helper/i18next.js";
import CryptoStatDetail from "../../components/CryptoStatDetail.js";
import NavBar from "../../components/NavBar.js";
import CryptoStats from "../../components/CryptoStats.js";
import {
  useGetCryptoGlobalStatsQuery,
  useGetCryptoListQuery,
  useGetTrendingCoinQuery,
} from "../../service/cryptoDataService.js";
import { useMeQuery } from "../../service/authService.js";
import { setUser } from "../../slice/applicationSlice.js";
import { HomePageLoader } from "../../components/loader/HomePageLoader.js";
const Home = () => {
  const { data: userData } = useMeQuery();
  const dispatch = useDispatch();
  dispatch(setUser(userData));
  const { t } = useTranslation();
  const { data: coinDataList, isSuccess: loadingList } =
    useGetCryptoListQuery();
  const { data: cryptoGlobalStats, isSuccess: loadingGlobalStats } =
    useGetCryptoGlobalStatsQuery();
  const { data: trendingCoin, isSuccess: loadingTrendingData } =
    useGetTrendingCoinQuery();

  const currentLanguage = useSelector(
    (state) => state.application.currentLanguage
  );

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, []);

  return (
    <div>
      {loadingList && loadingGlobalStats && loadingTrendingData ? (
        <div>
          <CryptoStats />
          <NavBar />
          <CryptoStatDetail
            coinDataList={coinDataList}
            cryptoGlobalStats={cryptoGlobalStats}
            trendingCoin={trendingCoin}
          />
          <CoinTable coinDataList={coinDataList} />
        </div>
      ) : (
        <HomePageLoader />
      )}
    </div>
  );
};
export default Home;
