import Carousel from "react-bootstrap/Carousel";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetCryptoListQuery } from "../service/cryptoDataService";

function TopAndICOBox({ coinDataList, cryptoGlobalStats }) {
  const trendingcoin = useSelector((state) => state.application.carsoul);
  // const cryptoGlobalStats = useSelector(
  //   (state) => state.application.headerdata?.data
  // );
  // const coinDataList = useSelector((state) => state.application.tabeldata);
  // const { data: coinDataList } = useGetCryptoListQuery();

  const [cgainto_dolortrillion, setcgainto_dolortrillion] = useState({});

  const indicatorLabels = ["e", "Second Slide", "Third Slide"];
  const { t } = useTranslation();
  useEffect(() => {
    if (trendingcoin != null && trendingcoin.length) {
      setcgainto_dolortrillion({
        a: (
          parseFloat(
            trendingcoin[0]?.item.data.market_cap?.replace(/\$|,/g, "")
          ) / 1e12
        ).toFixed(5),
        b: (
          parseFloat(
            trendingcoin[1]?.item.data.market_cap?.replace(/\$|,/g, "")
          ) / 1e12
        ).toFixed(5),
        c: (
          parseFloat(
            trendingcoin[2]?.item.data.market_cap?.replace(/\$|,/g, "")
          ) / 1e12
        ).toFixed(5),
        d: (
          parseFloat(
            trendingcoin[3]?.item.data.market_cap?.replace(/\$|,/g, "")
          ) / 1e12
        ).toFixed(5),
        e: (
          parseFloat(
            trendingcoin[4]?.item.data.market_cap?.replace(/\$|,/g, "")
          ) / 1e12
        ).toFixed(5),
        f: (
          parseFloat(
            trendingcoin[5]?.item.data.market_cap?.replace(/\$|,/g, "")
          ) / 1e12
        ).toFixed(5),
      });
    }
  }, [trendingcoin]);

  return (
    <div className="h-100">
      {coinDataList && (
        <Carousel
          controls={false}
          indicators={true}
          indicatorLabels={indicatorLabels}
          data-bs-theme="dark"
          className=""
          style={{
            boxShadow: "1px 1px 8px 1px rgba(0,0,0,0.2)",
            borderRadius: "20px",
            height: "100%",
          }}
        >
          <Carousel.Item className="carousel_in_main">
            <div className="h-100 d-flex flex-column justify-content-center p-3">
              <div>
                <h6 style={{ color: "black" }}>
                  {" "}
                  <i
                    className="bi bi-arrow-up-circle-fill"
                    style={{ color: "green" }}
                  ></i>{" "}
                  {t("carsoul.top")}
                </h6>
              </div>
              <div>
                <Table className="custom-table">
                  <thead>
                    <th>Name</th>
                    <th>Price</th>
                  </thead>
                  <tbody>
                    <tr style={{ backgroundColor: "white" }}>
                      <td>
                        <img src={coinDataList[0].image} width={20} />{" "}
                        {coinDataList && coinDataList[0].name}
                      </td>

                      <td>${coinDataList && coinDataList[0].current_price}</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={coinDataList[1].image} width={20} />{" "}
                        {coinDataList && coinDataList[1].name}
                      </td>

                      <td>${coinDataList && coinDataList[1].current_price}</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={coinDataList[2].image} width={20} />{" "}
                        {coinDataList && coinDataList[2].name}
                      </td>

                      <td>${coinDataList && coinDataList[2].current_price}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel_in_main">
            <div
              className="h-100 d-flex flex-column justify-content-center"
              style={{ padding: "27px" }}
            >
              <div>
                <h6
                  style={{ color: "black", marginTop: "2px" }}
                  // title="ICOs are another form of cryptocurrency that businesses use in order to raise capital. Through ICO trading platforms, investors receive unique cryptocurrency “tokens” in exchange for their monetary investment in the business."
                >
                  {" "}
                  {t("carsoul.ico")}
                </h6>
              </div>
              <div>
                <Table className="custom-table">
                  <tbody>
                    <tr>
                      <td>{t("carsoul.upcoicos")}</td>
                      <td
                        style={{
                          color: "red",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {cryptoGlobalStats?.upcoming_icos}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("carsoul.ongoingicos")}</td>
                      <td
                        style={{
                          color: "green",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {cryptoGlobalStats?.ongoing_icos}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("carsoul.enicos")}</td>
                      <td
                        style={{
                          color: "blue",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {cryptoGlobalStats?.ended_icos}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      )}
    </div>
  );
}

export default TopAndICOBox;
