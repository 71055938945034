import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CoinInfo from "./CoinInfo.js";
import { useSelector } from "react-redux";
import i18n from "../../helper/i18next.js";
import CryptoStats from "../../components/CryptoStats.js";
import NavBar from "../../components/NavBar.js";
import CoinGraphs from "./CoinGraphs.js";
import {
  useGetActualChartDataQuery,
  useGetCoinDetailQuery,
  useGetPredictionChartDataQuery,
} from "../../service/cryptoDataService.js";
import CoinInfoLoader from "../../components/loader/CoinInfoLoader.js";
const CoinDetail = () => {
  const location = useLocation();
  const currentLanguage = useSelector(
    (state) => state.application.currentLanguage
  );
  const { data: coinData, isSuccess: loadingCoinData } = useGetCoinDetailQuery(
    location.state?.id
  );
  const { data: accuracyCoinData, isSuccess: loadingAccuracyChart } =
    useGetActualChartDataQuery();
  const { data: predictionCoinData, isSuccess: loadingPredictionChart } =
    useGetPredictionChartDataQuery();
  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  return (
    <div>
      {loadingCoinData && loadingPredictionChart && loadingAccuracyChart ? (
        <div className="App">
          <CryptoStats />
          <NavBar landing={false} />
          <div style={{ marginTop: "5px" }} className="container-xxl">
            <div className="w-100 d-flex">
              <div className="w-25">
                <div style={{ width: "100%", height: "100%" }}>
                  <CoinInfo coinData={coinData} />
                </div>
              </div>
              <div className="w-75 d-flex flex-column">
                <CoinGraphs
                  accuracyCoinData={accuracyCoinData}
                  predictionCoinData={predictionCoinData}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CoinInfoLoader />
      )}
    </div>
  );
};

export default CoinDetail;
