import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Card } from "react-bootstrap";

const initialOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "USD",
};

const PaypalCheckout = () => {
  const handleApprove = async (data, actions) => {
    try {
      await actions.order.capture();
      alert("Payment successful!");
    } catch (error) {
      console.error("Payment failed:", error);
    }
  };

  return (
    <div className="d-flex justify-content-center p-3">
      <Card className="w-50 ">
        <Card.Body>
          <Card.Title className="text-center">Payment</Card.Title>
          <Card.Text>
            <PayPalScriptProvider>
              <PayPalButtons
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: "10",
                        },
                      },
                    ],
                  });
                }}
                onApprove={handleApprove}
                style={{
                  layout: "vertical",
                  size: "responsive",
                  color: "blue",
                }}
              />
            </PayPalScriptProvider>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PaypalCheckout;
