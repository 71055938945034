import React from "react";
import { createPaymentIntent } from "../../utils/auth";
import StripeCheckout from "react-stripe-checkout";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Stripecheckout = () => {
  const user = useSelector((state) => state.application.user);
  const navigate = useNavigate();
  const handleToken = async (token) => {
    try {
      const amount = 10;
      const paymentIntentResponse = await fetch(
        "http://localhost:5000/payments",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount,
            currency: "usd",
            userId: user.id,
            tokenId: token.id,
          }),
        }
      );
      console.log(paymentIntentResponse, "paymentIntentResponse");
      if (!paymentIntentResponse.ok) {
        throw new Error("Failed to create payment intent");
      }

      const paymentIntent = await paymentIntentResponse.json();

      // const result = await fetch("http://localhost:5000/payments/confirm", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     tokenId: token.id,
      //     paymentIntentId: paymentIntent.id,
      //   }),
      // });

      // if (!result.ok) {
      //   throw new Error("Payment confirmation failed");
      // }

      // console.log("Payment successful!");
      const update = await fetch(`http://localhost:5000/users/${user.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ planType: "Free" }),
      });
      console.log(update, "update");
    } catch (error) {
      console.error("Error during checkout:", error);
    }
    navigate("/");
  };
  return (
    <StripeCheckout
      stripeKey="pk_test_51PpV3nSDHvxmv5aUGsmFIKfrnvSeCt4Eplz6MIdp1RX23ZQIi7wOTxkmhBh8Bev9xfWrECwc9JgbcmCYZyn695y900q5R4NHek"
      token={handleToken}
      amount={1}
      currency="USD"
      name="Your Company"
      description={`Pay for Plan`}
      zipCode={true}
      // closed={() => navigate("/")}
    >
      <button className="btn btn-secondary btn-sm p-0">Continue</button>
    </StripeCheckout>
  );
};

export default Stripecheckout;
