import { authService } from "../service/authService";
import { cryptoDataService } from "../service/cryptoDataService";
import { subscriptionService } from "../service/subscriptionService";

const middleware = [
  authService.middleware,
  cryptoDataService.middleware,
  subscriptionService.middleware,
];

export default middleware;
