import React, { useEffect, useState } from "react";
import { Form, Button, Spinner, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../helper/i18next";
import img from "../../asset/img/logo2.png";
import { getUserByEmail, validateUser } from "../../utils/auth";
import { setauth, setUser } from "../../slice/applicationSlice";
import { enqueueSnackbar } from "notistack";
import {
  useRegisterUserMutation,
  useUserLoginMutation,
} from "../../service/authService";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { LoginType } from "../../constants/loginType";

const LoginForm = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisiblity, setPasswordVisibility] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigate = useNavigate();
  const [login, { isLoading }] = useUserLoginMutation();
  const [userResgister] = useRegisterUserMutation();
  const currentLanguage = useSelector(
    (state) => state.application.currentLanguage
  );
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email && !password) {
      setEmailError(true);
      setPasswordError(true);
      return;
    } else if (!email || !password) {
      !email && setEmailError(true);
      !password && setPasswordError(true);
      return;
    } else {
      setEmailError(false);
      setPasswordError(false);
      login({ email, password })
        .unwrap()
        .then((res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Login successful",
          });
          dispatch(setUser(JSON.stringify(res?.data)));
          dispatch(setauth(true));
          navigate("/");
        })
        .catch(() => {
          enqueueSnackbar({
            variant: "error",
            message: "Invalid email or password",
          });
        });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
      setEmailError(!value);
    } else if (name === "password") {
      setPassword(value);
      setPasswordError(!value);
    }
  };
  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, []);

  const getUserDetails = async (accessToken) => {
    const response = await fetch(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user details");
    }

    const userData = await response.json();
    const userDetails = {
      email: userData.email,
      firstName: userData.given_name,
    };
    return userDetails;
  };
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      const data = await getUserDetails(response.access_token);
      login({ ...data, type: LoginType.GOOGLE })
        .unwrap()
        .then((res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Login successful",
          });
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("userDetail", JSON.stringify(res?.data));
          dispatch(setUser(JSON.stringify(res?.data)));
          dispatch(setauth(true));
          navigate("/");
        })
        .catch(() => {
          enqueueSnackbar({
            variant: "error",
            message: "Something went wrong",
          });
        });
    },
    onError: () => {
      console.log("Login Failed");
    },
    scope: "openid email profile",
  });
  return (
    <div className="login_container">
      <Form
        onSubmit={handleSubmit}
        className="container_main_login"
        style={{
          background: "rgba(13, 110, 253, 0.1)",
          borderRadius: "15px",
          overflow: "hidden",
          border: "2px solid rgba(13, 110, 253, 0.1)",
        }}
      >
        <div className="w-100" style={{ left: "0", top: "0%" }}>
          <h2
            className="d-flex  justify-content-center align-items-center "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              fontWeight: "bolder",
              height: "9vh",
              backgroundColor: "white",
            }}
          >
            <img
              style={{ marginRight: "10px" }}
              src={img}
              alt="ii"
              height={35}
            />
            {t("ribbon.brand")}
          </h2>
        </div>

        <div className="h-auto p-5  login_form d-flex flex-column justify-content-between">
          <Form.Group controlId="formBasicEmail" className="mb-3">
            <Form.Label>{t("loginpage.emailaddres")}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t("loginpage.enteremail")}
              value={email}
              name="email"
              onChange={handleChange}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: emailError ? "block" : "none" }}
            >
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicPassword" className="mb-3">
            <Form.Label>{t("loginpage.pass")}</Form.Label>
            <InputGroup>
              <Form.Control
                type={passwordVisiblity ? "text" : "password"}
                name="password"
                placeholder={t("loginpage.enpass")}
                value={password}
                onChange={handleChange}
              />
              <InputGroup.Text
                onClick={() => setPasswordVisibility(!passwordVisiblity)}
              >
                {passwordVisiblity ? (
                  <i class="bi bi-eye-slash"></i>
                ) : (
                  <i class="bi bi-eye"></i>
                )}
              </InputGroup.Text>
            </InputGroup>

            <Form.Control.Feedback
              type="invalid"
              style={{ display: passwordError ? "block" : "none" }}
            >
              Please provide a password
            </Form.Control.Feedback>
          </Form.Group>
          {/* <Link className="forget_pass"> {t("loginpage.forpass")}</Link> */}
          <Button
            type="submit"
            className="border-0 btn btn-primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              t("loginpage.login")
            )}
          </Button>
          <p className="text-center my-1">Or</p>
          <button
            type="button"
            className="login-with-google-btn mb-3"
            onClick={() => handleGoogleLogin()}
          >
            Continue with Google
          </button>
          <div>
            {t("loginpage.dhaa")}{" "}
            <Link className="forget_pass" to="/signup">
              {" "}
              {t("loginpage.creacc")}
            </Link>
          </div>

          {/* <GoogleLogin
            onSuccess={(response) => {
              console.log(response.credential);
              userResgister({ token: response.credential, type: "Google" });
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          /> */}
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
